<template>
  <v-app :id="!$vuetify.theme.dark ? 'app_div' : 'app_div_dark'">
    <v-main id="main_div">
      <transition name="fade">
        <router-view
          v-if="ready || $route.path == '/login' || $route.path == '/register'"
          :key="$route.path"
        >
        </router-view>

        <div v-else style="text-align: center; margin-top: 100px">
          <v-progress-circular
            indeterminate
            color="primary"
            size="200"
          ></v-progress-circular>
        </div>
      </transition>
      <v-dialog v-model="dialog" width="700" persistent>
        <v-card>
          <v-card-title class=""> User Agreement </v-card-title>

          <v-card-text>
            <div
              @scroll="check_scroll"
              id="eula-body"
              style="height: 340px; overflow-y: scroll"
            >
              <iframe
                name="ifdoc"
                onload="window.frames['ifdoc'].scrollTo(250,250)"
                style="pointer-events: none; overflow: hidden"
                src="https://view.officeapps.live.com/op/embed.aspx?src=http://onecup-assets-public.s3.us-west-2.amazonaws.com/documents/eula/v1/eula.doc"
                width="100%"
                height="6750px"
                scrolling="no"
                frameborder="0"
                >This is an embedded
                <a target="_blank" href="http://office.com">Microsoft Office</a>
                document, powered by
                <a target="_blank" href="http://office.com/webapps"
                  >Office Online</a
                >.</iframe
              >
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="oc_gray" @click="decline_agreement">
              <span class="mb-n1">I decline</span>
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!scrolled_to_bottom"
              @click="accept_agreement"
            >
              <span class="mb-n1">I accept</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
    <!-- <events></events> -->
    <initialForm
      v-if="
        $store.getters.getUser &&
        $store.getters.getUserDDB &&
        (!sites || (sites && sites.length < 1))
      "
      :active="dialog_initial"
    />
        <v-snackbar v-model="error_snackbar" :timeout="3000" color="error">
      {{ error }}
    </v-snackbar>
    <v-snackbar v-model="success_snackbar" :timeout="3000" color="success">
      {{ error }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { eventBus } from "./main.js";
import Vue from "vue";
import initialForm from "@/components/initial_form.vue";
//import events from "@/components/Events.vue";
import eula from "@/data/eula.json";
import moment from "moment";

export default {
  data: () => ({
    thing_name: "",
    ready: true,
    dialog: false,
    eula: eula,
    scrolled_to_bottom: false,
    dialog_initial: false,

          error: "",
      error_snackbar: false,
      success_snackbar: false,
  }),
  components: {
   // events,
    initialForm,
  },
  name: "App",
  methods: {
    fetched_user() {
      this.ready = true;

      window.onbeforeunload = function () {
        window.scrollTo(0, 100);
      };
      if (this.$store.getters.getUser != null) {
        this.$store.dispatch("DDB_GET_ENUMS");
        this.$store.dispatch("DDB_GET_CUSTOMER", true);
      }

  
    },

    check_scroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.scrolled_to_bottom = true;
      }
    },
    async decline_agreement() {
      await this.$store.dispatch("logout");
      this.$store.state.button = true;
      console.clear();
      localStorage.clear();
      this.dialog = false;
      this.$router.push("/login");
    },
    async accept_agreement() {
      console.log({
            customer_id: this.customer.id,
            owner: this.customer.id,
            author: {
              id: this.$store.getters.getUser.username,
              first_name: this.$store.getters.getUser.attributes.given_name,
              last_name: this.$store.getters.getUser.attributes.family_name,
            },
            updatedBy: this.$store.getters.getUser.username,
            agreement_type: "EULA",
            agreement_accepted: "YES",
            agreement_version: this.eula.version,
            agreement_response_datetime: moment().format(
              "YYYY-MM-DDTHH:mm:ss.sssZ"
            ),
          })
    },

    agreed_to_version(arrs) {
      for (const i in arrs) {
        if (
          arrs[i].agreement_version == eula.version &&
          arrs[i].agreement_accepted == "YES" &&
          arrs[i].agreement_type == "EULA"
        ) {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    customer() {
      return this.$store.getters.getUserDDB;
    },
    sites() {
      return this.$store.getters.getSite;
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
  },
  watch: {
    customer() {

      if (
        this.$store.getters.getUserDDB &&
        (this.$route.path.includes("login") ||
          this.$route.path.includes("register")) 
      ) {
        const redirectPath = this.$route.query.redirect || "/";

        try {
          this.$router.push(redirectPath);
        } catch (e) {
          return (e);
        }
      }

      if (
        this.$store.getters.getUserDDB &&
        this.$store.getters.getUserDDB.type == "USER" &&
        this.$store.getters.getUserDDB.initial_login_web !=
          "YES" &&
        !(
          this.$route.path.includes("login") ||
          this.$route.path.includes("register")
        )
      ) {
        eventBus.$emit("open_tutorial");
         var input = {
          id: this.$store.getters.getUser.username,
          updatedBy: this.$store.getters.getUser.username,
          customer_initial_login_web: 'YES'
        };

      console.log(input)
        
      }
    },

    sites() {
      if (this.sites !== false) {
        if (
          this.$store.getters.getUserDDB &&
          this.$store.getters.getUserDDB.type != "USER" &&
          this.$store.getters.getSite.length < 1 &&
          !(
            this.$route.path.includes("login") ||
            this.$route.path.includes("register")
          ) &&
          this.customer_id == this.$store.getters.getUser.username
        ) {
          this.dialog_initial = true;
        }
      }
    },

  },

  async created() {
        eventBus.$on("add_button_alert", (params) => {
      this.error = params.msg
      if (params.err) {
        this.error_snackbar = true
      } else {
        this.success_snackbar = true
      }
    });
    eventBus.$on("fetched_user", () => this.fetched_user());
    Vue.prototype.$globEventParams = Vue.observable({
      
    });
    Vue.prototype.$selectedAnimals = Vue.observable({
      animals: [],
      header: {
        text: "Classification",
        value: "animal_classification",
      },
    });
    Vue.prototype.$selectedTreatments = Vue.observable({ treatments: [] });
    Vue.prototype.$selectedPhenotypes = Vue.observable({ phenotypes: [] });
    Vue.prototype.$selectedMeasurements = Vue.observable({ measurements: [] });

    Vue.prototype.$unverifiedUser = Vue.observable({ unverified_user: null });
  },
};
</script>


<style lang="css">
/* #events {
  margin-top: -50px;
} */
/* #register_stepper{
  height: 383px;
}
#register_stepper > div {
  height: 383px;
}
#register_stepper > div > div {
  height: 100% !important;
} */

.zoom_image_tooltip {
  opacity: 1 !important;
  padding: 0px !important;
}

.v-small-dialog__menu-content {
  z-index: 300 !important;
}
.oc_filter_main_div {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span { 
  height: 56px !important;
}

#download_notification_img {
  bottom: 17px;
  position: absolute;
  right: 10px;
  height: 40px;
  width: 40px;
  background: hsla(0, 0%, 100%, 0.8);
}
#fullscreen_notification_img {
  bottom: 17px;
  position: absolute;
  right: 60px;
  height: 40px;
  width: 40px;
  background: hsla(0, 0%, 100%, 0.8);
}
.pagination_select > div > div > div > div  {
  color: #616161 !important;
}
.blue_on_hover:hover {
  color: #003F51 !important;
}
.blue_on_hover {
  color: #0090a4 !important;
}

.v-stepper__header {
  height: 38px !important;
}

.v-stepper__step {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.v-btn.v-size--large > .v-btn__content {
  margin-top: 4px;
}
.v-calendar-weekly__day-label > button {
  height: 25px !important;
  width: 25px !important;
  margin-bottom: 2px;
}
.v-autocomplete.v-select.v-input--is-focused input {
  min-width: 2px !important;
}
#full_width_ed > div > span {
  width: 100%;
}
.tag_overlay {
  font-weight: 700;
  background-color: rgba(17, 17, 17, 0.6);
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  border-radius: 5px;
}
.ch_col_lg {
  height: calc(40vh + 4px);
}
.ch_col_lg > div {
  height: 38.5vh;
}
.ch_col {
  height: 25vh;
}
.ch_col > div {
  height: 22vh;
}
.title_span {
  position: absolute;
  top: 10px;
}
.selected_chip {
  color: #0090a4 !important;
  caret-color: #0090a4 !important;
  background-color: #d9f3f7 !important;
}
.herd_group_select {
  margin-top: 2px;
}
.assign_select > div > div > div {
  height: 32px !important;
}
.assign_select > div > div {
  background-color: inherit !important;
}
.assign_select > span {
  margin-top: 1px;
  color: #616161 !important;
}
/* .assign_select > div > div > div > label {
  left: 10px !important;
} */
.assign_select_v > div > div > div > label {
  margin-top: -4px !important;
}
.standin_label_select > div > div > div > label {
  margin-left: 0px !important;
}
.assign_select > div > div > .v-input__append-inner {
  margin-top: 0px !important;
  padding-top: 4px !important;
}
.button_text_field > div > div > div > label {
  transform: translateY(-17px) scale(0.75) !important;
}
.assign_select > div > div > div > label {
  top: 10px !important;
}

.assign_select.v-text-field--rounded > div > div > div > label {
  top: 10px !important;
  margin-left: 10px;
}
.assign_select_v > div > div > div > .v-input__append-inner {
  margin-top: 0px !important;
  padding-top: 7px !important;
}
.assign_select > div > div {
  min-height: 30px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.assign_select > div > div > div > div > div > i {
  margin-top: -5px;
}

.font_400 {
  font-weight: 400 !important;
}

.assign_select_aa > div > div {
  min-height: 30px !important;
}
.assign_select_aa > div > div > div {
  margin-top: -4px;
}

.v-data-table__empty-wrapper > td {
  font-size: 18px !important;
}
.no_data {
  margin-top: 2vh;
  margin-bottom: 4vh;
  text-align: center;
  font-size: 30px;
}
.save_notes_btn {
  float: right;
  margin-bottom: 10px;
}
#app_div {
  background: rgba(0, 0, 0, 0) url("./assets/background_blur_dark.png")
    no-repeat fixed 0% 0%;
  background-size: cover;
}
#app_div_dark {
  background: rgba(0, 0, 0, 0) url("./assets/background_blur_dark.png")
    no-repeat fixed 0% 0%;
  background-size: cover;
}
.v_duration_overlay_dn > .v-overlay__content {
  width: 96%;
}
.no_edit > .v-small-dialog__activator {
  pointer-events: none;
  padding-left: 4px;
}

.show_heads > .v-small-dialog__activator > .v-small-dialog__activator__content {
  display: block;
}

.show_heads_no_edit
  > .v-small-dialog__activator
  > .v-small-dialog__activator__content {
  display: block;
}
.show_heads_no_edit > .v-small-dialog__activator {
  pointer-events: none;
}
.disabled_edit_dialog {
  pointer-events: none;

}

.livestock_table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover
  > .non-hl
  > .v-small-dialog__activator {
  border-color: #003f51;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 3px;
  margin-right: -1px;
  margin-left: -1px;
  box-sizing: border-box;
}
.non-hl > .v-small-dialog__activator {
  padding-left: 4px;
}

.non-hl-pedigree > .v-small-dialog__activator {
  padding-left: 4px;
}

.livestock_table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover
  > .non-hl > .enabled_edit_dialog
  > .v-small-dialog__activator {
  border-color: #003f51;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 3px;
  margin-right: -1px;
  margin-left: -1px;
  box-sizing: border-box;
}

.theme--dark .livestock_table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover
  > .non-hl > .enabled_edit_dialog
  > .v-small-dialog__activator {
  background-color: #1E1E1E;
}

.theme--light .livestock_table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover
  > .non-hl > .enabled_edit_dialog
  > .v-small-dialog__activator {
  background-color: white;
}
.non-hl > .enabled_edit_dialog > .v-small-dialog__activator {
  padding-left: 4px;
}

.no_edit > .enabled_edit_dialog > .v-small-dialog__activator {
  pointer-events: none;
  padding-left: 4px;
}

.no_edit > div > .v-small-dialog__activator {
  pointer-events: none;
}

.non-hl-pedigree > .v-small-dialog__activator {
  padding-left: 4px;
}



.disabled_edit_dialog + .v-small-dialog__activator__content + .v-small-dialog__activator {
  pointer-events: none !important;
  padding-left: 4px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.map_sidebar_item_name {
  text-align: left;
}
.distance_list_close {
  display: inline-block;
  width: 264px;
  margin-top: 6px;
}
.distance_list_close > button {
  float: right;
}
.distance_list_close > h3 {
  float: left;
  padding-left: 6px;
}
.distance_list_item {
  width: 264px;
}
.distance_list_title {
  font-family: "MarriWeather";
  font-size: 14px;
  color: gray;
}
.group_title {
  text-align: left;
}
.distance_list_content {
  width: 200px !important;
}
.map_icon_color_picker .v-color-picker__canvas {
  display: none !important;
}
.map_icon_color_picker .v-color-picker__controls {
  display: none !important;
}
.edit_item_cb {
  display: flex;
  height: 28px;
}
.cond_tf {
  width: 100%;
}
.cb_label {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  margin-right: 6px;
  color: #808080;
}

.gm-ui-hover-effect {
  display: none !important;
}
.gm-style-iw {
  padding: 4px !important;
  padding-bottom: 6px !important;
  color: #0090a4 !important;
  padding-top: 2px !important;
  max-height: 350px !important;
  border-radius: 4px !important;
}
.gm-style-iw-d {
  max-height: 320px !important;
}
.gm-style-iw-d {
  overflow-x: hidden !important;
}
#full_size_ct_div {
  width: 100%;
}
.nav_arrows_f {
  float: right;
}

@media only screen and (max-width: 600px) {
  .table_pagination > .v-data-footer__select {
    display: none !important;
  }
  .table_pagination > .v-data-footer__pagination {
    margin-left: 10px !important;
  }
  .mb_hidden {
    display: none !important;
  }
   .mb_display {
    display: block !important;
  }
  .mb_max_width {
    width: 100% !important;
  }
}
.table_pagination {
  width: 500px;
  margin-left: auto;
}

.table_pagination_notes {
  float: right;
}
.table_search_field {
  width: 280px;
}
.link_entry {
  height: 39px !important;
  min-height: 0px !important;
}

#eventDrawer > .v-navigation-drawer__content {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
#eventDrawer > .v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.direction_sort {
  padding-left: 12px;
}
.filter_heading {
  float: right;
}
.table_link {
  color: blue !important;
}
.green_color {
  color: rgb(0, 167, 0);
}
.orange_color {
  color: orange;
}
.gray_color {
  color: grey;
}
.table_list_item {
  padding-left: 0px !important;
  padding-right: 0px !important;
  max-width: 90px;
}
.filter_btn {
  max-height: 28px;
}
.select_filter {
  margin-bottom: -2px !important;
}
.select_filter > div > div > div > div > div {
  color: gray;
  font-size: 14px;
}
.select_filter_ac > div > div > div > input {
  color: gray !important;
  font-size: 14px;
}
.select_filter > div > div > .v-select__slot > .v-input__append-inner {
  margin-top: 4px !important;
}
.select_filter > div > div > fieldset {
  max-height: 34px;
}
.select_filter > div > div > .v-select__slot {
  max-height: 30px;
}

.error_span {
  color: red;
  font-size: 14px;
  margin-left: 18px;
}
.row {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.row.no-gutters > .hor_gutters {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 12px;
  padding-right: 12px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.table_tabs {
  width: unset;
  min-width: 200px !important;
}
.table_tabs_summary_dashboard {
  float: left;

  width: 690px !important;
}
.table_tabs_hp {
  float: left;
  min-width: 200px !important;
}
.oc_table_header {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}
.v-slide-group__prev--disabled {
  display: none !important;
}
.v-data-footer {
  border-top: none !important;
}
.text_field_col {
  text-align: left;
  padding-top: 0px;
}
.v-input--radio-group__input {
  display: block !important;
}
.v-input--radio-group--column .v-radio {
  margin-bottom: 0px !important;
  display: inline-flex !important;
  margin-right: 16px;
}
.whole-day.d-flex {
  display: none !important;
}
.dashboard_table_pagination .v-data-footer__select div {
  display: none !important;
}

.vtb-item-players .vtb-player {
  padding: 0px !important;
}
.vtb-item-players .defeated {
  background-color: unset !important;
}

.tree-container {
  pointer-events: none;
}
.node-slot {
  pointer-events: none !important;
}

.vue-tree {
  transform: scale(1) translate(220px, -48px) !important;
}
.dom-container {
  transform: scale(1) translate(220px, -48px) !important;
}
</style>


import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store.js";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/MainPages.vue"),
    },
    {
      path: "/animals/:animal_index",
      name: "animals",
      component: () => import("../views/Livestock.vue"),
      props: true,
    },
    {
      path: "/animal-identification",
      name: "animal_identification",
      component: () => import("../views/AnimalIdentification.vue"),
      props: true,
    },
    {
      path: "/notifications/:date_filter",
      name: "notifications",
      component: () => import("../views/Notifications.vue"),
      props: true,
    },
    {
      path: "/treatments",
      name: "treatments",
      component: () => import("../views/MedicalRecords.vue"),
    },
    {
      path: "/sharing",
      name: "sharing",
      component: () => import("../views/Collaborations.vue"),
    },

    {
      path: "/alert-card",
      name: "alert card",
      component: () => import("../components/AlertCard.vue"),
    },
    // {
    //   path: '/customersettings',
    //   name: 'customer settings',
    //   component: () => import('../views/CustomerSettings.vue'),
    // },
    {
      path: "/map",
      name: "map",
      component: () => import("../views/Map.vue"),
    },
    {
      path: "/option_menu",
      name: "option_menu",
      component: () => import("../components/OptionMenu.vue"),
    },
    {
      path: "/sites",
      name: "site",
      component: () => import("../views/SiteSettings.vue"),
    },

    {
      path: "/account",
      name: "account",
      component: () => import("../views/UserSettings.vue"),
      props: true,
    },
    {
      path: "/notification-settings",
      name: "notification-settings",
      component: () => import("../views/NotificationSettings.vue"),
      props: true,
    },
    {
      path: "/animal-dashboard",
      name: "animal_dashboard",
      component: () => import("../views/MainPages.vue"),
      props: true,
    },
    {
      path: "/health-dashboard",
      name: "health_dashboard",
      component: () => import("../views/MainPages.vue"),
      props: true,
    },
    {
      path: "/measurements-dashboard",
      name: "measurements_dashboard",
      component: () => import("../views/MainPages.vue"),
      props: true,
    },
    {
      path: "/phenotype-dashboard",
      name: "phenotype_dashboard",
      component: () => import("../views/MainPages.vue"),
      props: true,
    },

    {
      path: "/login",
      name: "Login",
      component: () => import("../views/Login.vue"),
    },
    {
      path: "/reports",
      name: "reports",
      component: () => import("../views/Reports.vue"),
    },
    {
      path: "/notes",
      name: "notes",
      component: () => import("../views/Notes.vue"),
    },
    {
      path: "/videos/:cameraname",
      name: "videos",
      component: () => import("../components/cameras.vue"),
      props: true,
    },

    {
      path: "/images",
      name: "images",
      component: () => import("../views/Images.vue"),
      props: true,
    },
    {
      path: "/analytics",
      name: "analytics",
      component: () => import("../views/Analytics.vue"),
    },

    {
      path: "/cameras",
      name: "cameras",
      component: () => import("../views/Cameras.vue"),
      props: true,
    },
    {
      path: "/footageuploads",
      name: "footageuploads",
      component: () => import("../views/MainPages.vue"),
    },
    {
      path: "/register/:register_type",
      name: "register",
      component: () => import("../views/Register.vue"),
      props: true,
    },
    {
      path: "*",
      name: "not found",
      component: () => import("../views/404.vue"),
    },
  ],
});

router.beforeResolve((to, from, next) => {
  const authService = store.state.user;
  if (!(to.name == "Login" || to.name == "register") && authService == null) {
    next({ name: "Login", query: { redirect: to.fullPath } });
  } else next();
});

export default router;
